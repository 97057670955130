import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/Home/HomePage/homePage.vue'),
    children:[
      {
        path: '/', // 首页
        name: 'HomePage',
        component: () => import("@/views/Home/HomePage/index.vue"),
      },
      {
        path: '/home/apply', // 知识产权申请
        name: 'Apply',
        meta:{
          navList:[{path:"/",title:"首页"},{title:"知识产权申请"}]
        },
        component: () => import("@/views/Home/Apply/index.vue"),
      },
      {
        path: '/home/applyDeatil', // 知识产权申请办事指南
        name: 'ApplyDeatil',
        component: () => import("@/views/Home/Apply/model/detail.vue"),
      },
      {
        path: '/home/using', // 知识产权金融
        name: 'Using',
        component: () => import("@/views/Home/Using/index.vue"),
      },
      {
        path: '/home/financial', // 知识产权金融
        name: 'Financial',
        component: () => import("@/views/Home/Financial/index.vue"),
      },
      {
        path: '/home/protect', // 知识产权保护
        name: 'Protect',
        component: () => import("@/views/Home/Protect/index.vue"),
      },
      {
        path: '/home/protectTable', // 知识产权保护 成都高新区维权站点名录
        name: 'ProtectTable',
        component: () => import("@/views/Home/Protect/model/table.vue"),
      },
      {
        path: '/home/service', // 知识产权服务
        name: 'Service',
        component: () => import("@/views/Home/Service/index.vue"),
      },
      {
        path: '/home/cash', // 政策兑现
        name: 'Cash',
        component: () => import("@/views/Home/Cash/index.vue"),
      },
      {
        path: '/home/news', // 通知新闻
        name: 'News',
        component: () => import("@/views/Home/News/index.vue"),
      },
      {
        path: '/home/law', // 法律法规
        name: 'Law',
        component: () => import("@/views/Home/Law/index.vue"),
      },
      {
        path: '/home/study', // 在线学习
        name: 'Study',
        component: () => import("@/views/Home/Study/index.vue"),
      },
      {
        path: '/home/detail', // 详情
        name: 'Detail',
        component: () => import("@/components/detail.vue"),
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
